@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #eee;
}

.tabAnim {
  z-index: 5;
}

.secondary-color {
  background-color: #37c6c1;
}

#toggleEnable:checked + label + #toggleDisable + label ~ .tabAnim {
  --tw-translate-x: 0%;
}

#toggleDisable:checked ~ .tabAnim {
  --tw-translate-x: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

img.App-logo {
  max-height: 3rem !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: cyan;
}

a:visited {
  color: magenta;
}

.main-content {
  text-align: center;
  margin-top: 3rem;
}
